// MANAGED BY App.js
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { history } from 'instantsearch.js/es/lib/routers'
import {
  InstantSearch,
  SearchBox,
  Configure,
  Pagination,
  useClearRefinements,
} from 'react-instantsearch'
import { useParams } from 'react-router-dom'

import CustomHits from './CustomHits'
import CustomHitsBeta from '../leads/CustomHitsBeta'
import { humanize, meilisearchClient } from '../entries/utils'
import RefinementListSelect from '../editor/common/RefinementListSelect'
import Form from '../leads/Form'
import DateRangePicker from '../leads/DateRangePicker'
import { DownloadLink } from '../leads/Search'

import Loading from '../Loading'

const Col = ({ children }) => <div className="col-md-6 col-lg-4 col-xl-3 mb-2">{children}</div>

const Wrapper = ({ defaultLayout = 'list' }) => {
  let [layout, setLayout] = useState(defaultLayout)
  let [searchParams, setSearchParams] = useState(false)
  // const dealership = useContext(DealershipContext)
  let { dealershipSlug, websiteSlug, pipelineId } = useParams()
  const navigate = useNavigate()
  let [loading, setLoading] = useState(true)

  // needs to work on both the webiste/leads and dealership/leads
  let slug = window.dealership?.slug || dealershipSlug

  const changeLayout = (layout) => {
    setLayout(layout)

    let url
    if (layout === 'kanban') {
      url = `/dealerships/${slug}/leads/kanban`
    } else {
      url = `/dealerships/${slug}/lead_clusters`
    }

    if (pipelineId !== undefined && pipelineId !== null) {
      url += `/pipelines/${pipelineId}`
    }

    navigate(url)
  }


  useEffect(() => {
    if (searchParams || !slug) {
      return
    }
    let url
    if (dealershipSlug) {
      url = `/dealerships/${slug}/leads.json`
    } else if (websiteSlug) {
      url = `/websites/${websiteSlug}/leads.json`
    }

    if (pipelineId !== undefined && pipelineId !== null) {
      url += `?pipeline_id=${pipelineId}`
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSearchParams(data)
        setLoading(false)
      })
  }, [])

  const pipelineCategoriesCount = searchParams?.pipeline?.categories?.length || 0
  return (
    <div className="pt-3 container-fluid">
      {searchParams.pipeline && searchParams.hasPipelines && (
        <div className="mb-3 w-100 border rounded py-2 px-3 bg-primary text-white d-flex align-items-center">
          <div>
            <i className="fa fa-info-circle mr-2" />
            You are viewing the <b>{searchParams.pipeline.name}</b> Pipeline
            {pipelineCategoriesCount > 0 && (
              <> ({pipelineCategoriesCount} lead categor{pipelineCategoriesCount === 1 ? 'y' : 'ies'})</>
            )}
          </div>
          <div className="ml-auto">
            <a
              href={`/dealerships/${dealershipSlug}/lead_settings`}
              className="btn btn-outline-light btn-sm"
            >
              View Settings
            </a>
          </div>
        </div>
      )}
      <div className="mb-2">
        <div className="btn-group btn-block">
          <button
            type="button"
            className={`btn ${layout === 'list' ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => changeLayout('list')}
          >
            <i className="fa fa-table mr-1" />
            Classic
          </button>
          <button
            type="button"
            className={`btn ${layout === 'kanban' ? 'btn-secondary' : 'btn-outline-secondary'}`}
            onClick={() => changeLayout('kanban')}
          >
            <i className="fa fa-bars mr-1" />
            Kanban Board
          </button>
        </div>
      </div>

      <div className={layout === 'list' ? 'd-block' : 'd-none'}>
        {loading && <Loading />}
        {searchParams && searchParams.searchFilters && <SearchLeadClusters {...searchParams} />}
      </div>

      {layout === 'kanban' && <CustomHitsBeta />}
    </div>
  )
}

function ClearRefinements({ translations }) {
  const { refine, isRefined } = useClearRefinements({})

  const handleClick = () => {
    localStorage.removeItem(`refinementList_leadCluster_${window.dealer_slug}`)
    refine()
  }

  return (
    <div className="ais-ClearRefinements">
      <button className="ais-ClearRefinements-button" onClick={handleClick}>
        {translations.resetButtonText}
      </button>
    </div>
  )
}

function renameTransformItems(items) {
  return items.map((item) => {
    if (item.label === 'Adwords') {
      item.label = 'Google'
    }
    return item
  })
}

const SearchLeadClusters = ({ searchFilters, users, leadStatusOptions }) => {
  let [recentlyUpdated, setRecentlyUpdated] = useState([])
  let { dealershipSlug } = useParams()

  const searchClient = meilisearchClient()

  let index = `LeadCluster_${process.env.RAILS_ENV}:last_lead_created_at:desc`

  useEffect(() => {
    if (dealershipSlug && recentlyUpdated?.length === 0) {
      let url = `/dealerships/${dealershipSlug}/lead_clusters/recently_updated.json`
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setRecentlyUpdated(data)
        })
    }
  }, [dealershipSlug])

  const savedSortBy = `LeadCluster_${process.env.RAILS_ENV}:last_lead_created_at:desc`
  const savedRefinements =
    JSON.parse(localStorage.getItem(`refinementList_leadCluster_${window.dealer_slug}`)) || {}
  const routing = {
    router: history(),
    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[savedSortBy]
        const refinementList = indexUiState?.refinementList || {}
        const refinementListString = JSON.stringify(refinementList)
        localStorage.setItem(
          `refinementList_leadCluster_${window.dealer_slug}`,
          refinementListString
        )
        const sortBy = indexUiState.sortBy || savedSortBy
        localStorage.setItem(`sortBy_leadCluster_${window.dealer_slug}`, sortBy)

        return {
          q: indexUiState.query,
          page: indexUiState.page,
          refinements: indexUiState?.refinementList || savedRefinements,
        }
      },
      routeToState(routeState) {
        return {
          [savedSortBy]: {
            query: routeState.q,
            page: routeState.page,
            refinementList: {
              ...routeState.refinements,
              ...(Object.keys(savedRefinements).length !== 0 ? savedRefinements : {}),
            },
          },
        }
      },
    },
  }

  return (
    <InstantSearch
      indexName={index}
      searchClient={searchClient}
      routing={routing}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure filters={searchFilters} hitsPerPage={40} />
      <div className="box p-3">
        <div className="row">
          <Col>
            <SearchBox className="mb-0" placeholder="Search Leads" />
          </Col>
          <Col>
            <RefinementListSelect
              attribute="lead_state"
              limit={20}
              defaultRefinement={['new']}
              transformItems={(items) =>
                items.map((item) => ({
                  ...item,
                  label: humanize(item.label),
                }))
              }
            />
          </Col>
          <Col>
            <RefinementListSelect attribute="user_name" limit={30} />
          </Col>
          <Col>
            <RefinementListSelect attribute="source" />
          </Col>
          <Col>
            <RefinementListSelect attribute="marketing_source" transformItems={renameTransformItems} />
          </Col>
          <Col>
            <RefinementListSelect attribute="category" limit={20} />
          </Col>
          <Col>
            <RefinementListSelect attribute="websites.name" buttonText="Website" limit={30} />
          </Col>
          <Col>
            <RefinementListSelect attribute="location_name" limit={30} />
          </Col>
          <Col>
            <DateRangePicker attribute="created_at_timestamp" />
          </Col>
          <Col>
            <ClearRefinements translations={{ resetButtonText: 'Clear Filters' }} />
          </Col>
          <Col>
            <RefinementListSelect attribute="requires_approval" />
          </Col>
          <Col>
            <div className="btn-group btn-block">
              <Form />
              <button
                id="leads-search-dropdown"
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></button>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                <DownloadLink />
              </div>
            </div>
          </Col>
        </div>
      </div>
      <CustomHits
        leadStatusOptions={leadStatusOptions}
        users={users}
        recentlyUpdated={recentlyUpdated}
      />
      <div className="py-3">
        <Pagination />
      </div>
    </InstantSearch>
  )
}

export default Wrapper
