import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../Loading'
import { Switch, Select, Input } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

const carGradeOptions = ['A', 'B', 'C', 'D', 'E'].map(o => ({ value: o, label: o }))
const minimumLeadOptions = [1, 2, 3, 4, 5, 6].map(o => ({ value: o, label: `${o} leads` }))

const SmartPublisherSettings = ({ smartPublisherRestriction }) => {
  let [loading, setLoading] = useState(false)

  let { dealershipSlug } = useParams()

  let [restrictByGrade, setRestrictByGrade] = useState(smartPublisherRestriction.restrict_by_grade || false)
  let [restrictByLeadNumber, setRestrictByLeadNumber] = useState(smartPublisherRestriction.restrict_by_leads || false)
  let [gradeRestrictionNumberOfDays, setGradeRestrictionNumberOfDays] = useState(7)
  let [leadRestrictionNumberOfDays, setLeadRestrictionNumberOfDays] = useState(3)
  let [minimumLeadNumberToRestrict, setMinimumLeadNumberToRestrict] = useState(3)
  let [leadCountingPeriod, setLeadCountingPeriod] = useState(24)

  const submit = () => {
    setLoading(true)

    let data = {
      dealership: {
        smart_publisher_restriction_attributes: {
          id: smartPublisherRestriction.id,
          restrict_by_grade: restrictByGrade,
          restrict_by_leads: restrictByLeadNumber
        }
      }
    }

    fetch(`/dealerships/${dealershipSlug}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: standardHeaders
    }).then(res => res.json())
      .then(res => {
        setLoading(false)
        console.log(res)
      })
  }

  return (
    <div className="p-3 w-100">
      <div className="d-flex mb-2">
        <h4>Smart Publisher Settings</h4>
      </div>

      <div className="box mb-2">
        <div className="py-4 px-3">
          <Switch
            label="Restrict cars by Car Grade"
            value={restrictByGrade}
            onChange={() => setRestrictByGrade(!restrictByGrade)}
            id="restrict-by-grade-switch"
          />
          {(restrictByGrade) && (
            <div className="mt-3">
              <Select
                options={carGradeOptions}
                isMulti
                label="Which car grades would you like to restrict?"
              />
              <Input
                label="How long would you like to restrict these items? (in days)"
                placeholder="Eg. 7 days"
                value={gradeRestrictionNumberOfDays}
                onChange={(e) => setGradeRestrictionNumberOfDays(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="box">
        <div className="py-4 px-3">
          <Switch
            label="Restrict cars by number of leads"
            value={restrictByLeadNumber}
            onChange={() => setRestrictByLeadNumber(!restrictByLeadNumber)}
            id="restrict-by-leads-switch"
          />
          {(restrictByLeadNumber) && (
            <div className="mt-3">
              <Select
                options={minimumLeadOptions}
                label="How many leads should a car have before we restrict the car?"
                value={{ label: `${minimumLeadNumberToRestrict} leads`, value: minimumLeadNumberToRestrict }}
                onChange={value => setMinimumLeadNumberToRestrict(value.value)}
              />
              <Select
                options={minimumLeadOptions}
                label="Select lead counting period (hours)"
                hint="Specify the time window for counting leads"
                value={{ label: `${leadCountingPeriod} hours`, value: leadCountingPeriod }}
                onChange={value => setLeadCountingPeriod(value.value)}
              />
              <Input
                label="How long would you like to restrict these items? (in days)"
                placeholder="Eg. 7 days"
                value={leadRestrictionNumberOfDays}
                onChange={(e) => setLeadRestrictionNumberOfDays(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="btn btn-outline-secondary mt-3" onClick={submit}>
        {loading ? "Loading..." : "Save Changes"}
      </div>
    </div>
  )
}

const Wrapper = () => {
  let [loading, setLoading] = useState(false)
  let [smartPublisherRestriction, setSmartPublisherRestriction] = useState(false)

  let { dealershipSlug } = useParams()

  useEffect(() => {
    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/cars/smart_publisher_settings.json`)
      .then(res => res.json()).then(res => {
        setSmartPublisherRestriction(res)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <SmartPublisherSettings smartPublisherRestriction={smartPublisherRestriction} />
  )
}

export default Wrapper