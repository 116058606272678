import { useEffect, useState } from 'react'
import { useFormState } from 'react-hook-form'

/**
 * Placeholder for -
 * https://tanstack.com/query/latest/docs/framework/react/guides/queries
 * We really should use this package if we want to enhance producivity and
 * improce the user experience of these clientside fetches.
 *
 */
export function useQuery<T>({
  query,
  queryKey,
}: {
  query: (key: string) => Promise<T>
  queryKey: string
}) {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      if (queryKey) {
        setLoading(true)
        try {
          const result = await query(queryKey)
          setData(result)
        } catch (error) {
          setError(error)
        }
      }
    }
    fetchData().then(() => {
      setLoading(false)
    })
  }, [query, queryKey])

  return { data, error, loading }
}

/** Basic fetch util, should be replaced by a global util */
export const fetchOnClient = async (path: string) => {
  const res = await fetch(path)
  return await res.json()
}

/** Show loading state in a form component */
export const PendingText = ({ text = 'Save Changes' }) => {
  const { isSubmitting } = useFormState()
  if (isSubmitting) {
    return <span>Loading...</span>
  }
  return <span>{text}</span>
}
